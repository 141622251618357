import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/iconcss/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
import uCharts from "@qiun/vue-ucharts";
Vue.component('msUchart',uCharts);
import {
    Button,
    Select ,
    Table ,
    TableColumn,
    Row,
    Col,
    Dialog,
    Tag,
    Radio,
    RadioGroup,
    Option,
    Upload,
    Message,
    MessageBox,
    Pagination,
    Image,
    Drawer,
    Descriptions,
    DescriptionsItem,
    Card,
    Carousel,
    CarouselItem,
    Divider,
    DatePicker,
    Empty,
    Breadcrumb,
    BreadcrumbItem,
    Alert,
    Input,
    Form,
    FormItem,
    ButtonGroup,
    InputNumber,
    Tabs,
    TabPane,
} from 'element-ui';
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Row);
Vue.use(Col);
Vue.use(Dialog);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Upload);
Vue.use(Pagination);
Vue.use(Image);
Vue.use(Drawer);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Divider);
Vue.use(DatePicker);
Vue.use(Empty);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Alert);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(ButtonGroup);
Vue.use(InputNumber);
Vue.use(Tabs);
Vue.use(TabPane);


import iView from "view-design";
import 'view-design/dist/styles/iview.css';
Vue.use(iView);

import http from "@/request/http";
import JsonExcel from "vue-json-excel";
Vue.prototype.$msMessage = Message;
Vue.prototype.$msConMessage = MessageBox;
Vue.component('downloadExcel',JsonExcel);

import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  // locale: "zh", // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
  locale: localStorage.getItem('lang') || 'zh',
  messages: {
    zh: require("./lang/zh"),
    uy: require("./lang/uy"),
  },
});


Vue.config.productionTip = false
Vue.prototype.$baseUrl = "http://yegidawerwms.munber.club/api/v1/";
Vue.prototype.$appUrl = "http://yegidawerwms.munber.club";
Vue.prototype.$http = http;
Vue.prototype.$lang = localStorage.getItem('lang') == 'zh' ? 2 : 1
Vue.prototype.$limit = 25;
Vue.prototype.$width = '80%';
Vue.prototype.$drawer = '100%';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
